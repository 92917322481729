<template>
  <li class="w-full">
    <div
      v-if="loading"
      class="w-full h-9 rounded-md animate-pulse bg-surface-300"
    ></div>

    <component
      :is="toName ? NuxtLink : 'div'"
      v-if="!loading"
      :to="{ name: toName }"
      class="grid grid-cols-[1.25rem_auto] items-center gap-3 px-3 py-2 rounded-[4px] w-full h-9 border group duration-300"
      :class="{
        'cursor-pointer': !!toName,
        'hover:bg-surface-100': !!toName && !isActive,
        'text-surface-700 font-medium border-transparent': !isActive,
        'bg-primary-50 text-primary-800 font-semibold border-primary-200':
          isActive,
      }"
    >
      <div class="w-full flex justify-center">
        <AppIcon
          :icon="icon"
          class="text-base"
          :class="{
            'text-surface-500': !isActive,
            'group-hover:text-surface-700 duration-300': !isActive && !!toName,
            'text-primary-800': isActive,
          }"
        />
      </div>
      <span class="text-sm">{{ label }}</span>
    </component>
  </li>
</template>

<script setup lang="ts">
const NuxtLink = resolveComponent('NuxtLink')

const route = useRoute()

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  toName: {
    type: String,
    required: false,
    default: undefined,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const isActive = computed(() => {
  if (!props.toName) return false

  if (!route.name) return false

  return route.name.toString() === props.toName
})
</script>
