<template>
  <nav
    class="w-64 h-screen fixed left-0 top-0 flex flex-col gap-4 px-4 py-3 select-none"
  >
    <!-- WORKSPACE MENU -->
    <div class="w-full flex flex-col gap-3">
      <!-- LOGO -->
      <div class="h-9 px-1">
        <div
          class="bg-contain bg-no-repeat h-full w-full cursor-pointer"
          style="background-image: url('/logo/studio_full_light.png')"
          @click="router.push({ name: 'index' })"
        />
      </div>
      <slot name="top" />
    </div>

    <ul class="flex flex-col gap-6 w-full">
      <slot name="nav-items" />
    </ul>
  </nav>
</template>

<script setup lang="ts">
const router = useRouter()
</script>
